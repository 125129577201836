export const loadHidePassPersonalInfoFromStorage = () => {
  const hidePassPersonalInfo = localStorage.getItem('hidePassPersonalInfo');
  if (hidePassPersonalInfo === null) return true;
  else return Boolean(hidePassPersonalInfo === 'true'); //Defaults to true.
};

export const saveHidePassPersonalInfoToStorage = hidePersonalInfo => {
  localStorage.setItem('hidePassPersonalInfo', hidePersonalInfo === true ? 'true' : 'false');
};

export const loadSelectedTagEventFromStorage = () => {
  const eventJSONString = localStorage.getItem('selectedTagEvent');
  if (eventJSONString === null) {
    return null;
  } else {
    return JSON.parse(eventJSONString);
  }
};

export const saveSelectedTagEventToStorage = tagEvent => {
  if (tagEvent) {
    localStorage.setItem('selectedTagEvent', JSON.stringify(tagEvent));
  } else {
    localStorage.clear('selectedTagEvent');
  }
};

export const loadSelectedTagEventLocationIDFromStorage = () => {
  return localStorage.getItem('selectedTagEventLocationID');
};

export const saveSelectedTagEventLocationIDToStorage = locationID => {
  localStorage.setItem('selectedTagEventLocationID', locationID);
};

export const loadVerifierScanTypeFromStorage = () => {
  const scanType = localStorage.getItem('verifier.scanType');
  if (scanType === 'locals-vaccinated') {
    return 'locals-vaccinated';
  } else {
    return 'incomming-visitors'; //Defaults to 'incomming-visitors'
  }
};

export const saveVerifierScanTypeToStorage = scanType => {
  localStorage.setItem('verifier.scanType', scanType);
};

export const loadVerifierInputTypeFromStorage = () => {
  const inputType = localStorage.getItem('verifier.inputType');
  if (inputType === 'camera') {
    return 'camera';
  } else {
    return 'manual'; // Default to manual.
  }
};

export const saveVerifierInputTypeToStorage = inputType => {
  localStorage.setItem('verifier.inputType', inputType);
};

export const loadVerifierTagFromStorage = () => {
  return localStorage.getItem('verifier.tag'); // Default to null (if not found).
};

export const saveVerifierTagToStorage = tag => {
  if (tag) {
    localStorage.setItem('verifier.tag', tag);
  } else {
    localStorage.removeItem('verifier.tag');
  }
};

export const loadVerifierTagTypeFromStorage = () => {
  return localStorage.getItem('verifier.tagType');
};

export const saveVerifierTagTypeToStorage = tagType => {
  if (tagType) {
    localStorage.setItem('verifier.tagType', tagType);
  } else {
    localStorage.removeItem('verifier.tagType');
  }
};

export const loadVerifierTagDescriptionFromStorage = () => {
  return localStorage.getItem('verifier.tagDescription'); // Default to null (if not found).
};

export const saveVerifierTagDescriptionToStorage = description => {
  if (description) {
    localStorage.setItem('verifier.tagDescription', description);
  } else {
    localStorage.removeItem('verifier.tagDescription');
  }
};
