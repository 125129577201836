import kcUtils from '../utils/keycloak';
import axios from 'axios';
import moment from 'moment';

var qs = require('qs');

export const ED_NOT_FOUND_ERROR_MSG = 'EDCard number not found';
export const AHA_FLIGHT_NOT_FOUND_ERROR_MSG = 'AHA Flight registration not found';
export const AHA_CASE_NOT_FOUND_ERROR_MSG = 'AHA Case registration not found';
export const SCAN_SERVER_ERROR_MSG = 'A system error occured while scanning (Error 500).';
export const SCAN_UNKOWN_OTHER_ERROR_MSG = 'Pass is not not found or not supported.';

export const APPROVAL_NOT_ALLOWED_ERROR_MSG = 'This user is not allowed to approve visitors.';
export const APPROVAL_SERVER_ERROR_MSG = 'An internal system error occured while approving (Error 500).';
export const APPROVAL_UNKOWN_OTHER_ERROR_MSG = 'An unkown error occured while approving. Please check your internet connection and try again.';

const _setTagEventOnContext = (context, tagEvent) => {
  context.commit('SET_SELECTED_TAG_EVENT', tagEvent);
  if (tagEvent !== null) {
    context.commit('SET_VERIFIER_TAG', tagEvent?._id);
    context.commit('SET_VERIFIER_TAG_TYPE', 'event');
    context.commit('SET_VERIFIER_TAG_DESCRIPTION', tagEvent?.name);
  } else {
    context.commit('SET_VERIFIER_TAG', null);
    context.commit('SET_VERIFIER_TAG_TYPE', null);
    context.commit('SET_VERIFIER_TAG_DESCRIPTION', null);
  }
};

export default {
  async getPassCodeInfoFromEdCard(context, edCard) {
    if (context.state.verifier.loadingPass) return; //Already loading pass.

    try {
      context.commit('SET_VERIFIER_LOADING_PASS', true);
      context.commit('SET_VERIFIER_SCAN_ERROR', null);
      context.commit('SET_VERIFIER_SCANNED_PASS', null);

      const { edNumber } = edCard;

      const host = context.state.baseUrlApi;
      const { data: passInfo } = await axios.get(`${host}/healthcheck-restful-api/verify/check/${edNumber}`);
      //Note: We add the existing info of the ed card to the scanned pass to be able to display flight number etc.
      context.commit('SET_VERIFIER_SCANNED_PASS', { ...edCard, ...passInfo });
    } catch (error) {
      switch (error.response?.status) {
        case 404:
          context.commit('SET_VERIFIER_SCAN_ERROR', ED_NOT_FOUND_ERROR_MSG);
          return;
        case 500:
          context.commit('SET_VERIFIER_SCAN_ERROR', SCAN_SERVER_ERROR_MSG);
          return;
        default:
          //Log the error.
          context.commit('SET_VERIFIER_SCAN_ERROR', SCAN_UNKOWN_OTHER_ERROR_MSG);
          // eslint-disable-next-line no-console
          console.error(error);
      }
    } finally {
      context.commit('SET_VERIFIER_LOADING_PASS', false);
    }
  },

  async getPassCodeInfoFromAhaFlight(context, ahaFlight) {
    if (context.state.verifier.loadingPass) return; //Already loading pass.

    try {
      context.commit('SET_VERIFIER_LOADING_PASS', true);
      context.commit('SET_VERIFIER_SCAN_ERROR', null);
      context.commit('SET_VERIFIER_SCANNED_PASS', null);

      const { edCardId } = ahaFlight;

      const host = context.state.baseUrlApi;
      const { data: passInfo } = await axios.get(`${host}/healthcheck-restful-api/verify/check/${edCardId}`);
      //Note: We add the existing info of the aha flight to the scanned pass to keep extra info.
      context.commit('SET_VERIFIER_SCANNED_PASS', { ...ahaFlight, ...passInfo });
    } catch (error) {
      switch (error.response?.status) {
        case 404:
          context.commit('SET_VERIFIER_SCAN_ERROR', AHA_FLIGHT_NOT_FOUND_ERROR_MSG);
          return;
        case 500:
          context.commit('SET_VERIFIER_SCAN_ERROR', SCAN_SERVER_ERROR_MSG);
          return;
        default:
          //Log the error.
          context.commit('SET_VERIFIER_SCAN_ERROR', SCAN_UNKOWN_OTHER_ERROR_MSG);
          // eslint-disable-next-line no-console
          console.error(error);
      }
    } finally {
      context.commit('SET_VERIFIER_LOADING_PASS', false);
    }
  },

  async getPassCodeInfoFromAhaCase(context, ahaCase) {
    if (context.state.verifier.loadingPass) return; //Already loading pass.

    try {
      context.commit('SET_VERIFIER_LOADING_PASS', true);
      context.commit('SET_VERIFIER_SCAN_ERROR', null);
      context.commit('SET_VERIFIER_SCANNED_PASS', null);

      const { udid } = ahaCase;
      const host = context.state.baseUrlApi;
      const data = { id: udid, version: 2 };
      const { tag } = context?.state?.verifier;

      let vaccineInfo;
      if (tag) {
        //We do special post request to add to tag as data.
        const { tagDescription: description, tagType: type } = context?.state?.verifier;
        data.tag = JSON.stringify({ id: tag, description, type });
        const response = await axios.post(`${host}/healthcheck-restful-api/vaccine/verification/check`, data);
        vaccineInfo = response?.data;
      } else {
        //We do a regular get request with the query params.
        const query = qs.stringify(data);
        const response = await axios.get(`${host}/healthcheck-restful-api/vaccine/verification/check?${query}`);
        vaccineInfo = response?.data;
      }
      if (!vaccineInfo || vaccineInfo === '') {
        throw { response: { status: 204 } }; //Same as a 204 no content
      }

      const passInfo = { healthState: 'FULLY_VACCINATED', vaccine: vaccineInfo };
      //Note: We add the existing info of the aha case to the scanned pass to keep extra info.
      context.commit('SET_VERIFIER_SCANNED_PASS', { ...ahaCase, ...passInfo });
    } catch (error) {
      switch (error.response?.status) {
        case 204:
          {
            const passInfo = { healthState: 'NOT_FULLY_VACCINATED', vaccine: null };
            //Note: We add the existing info of the aha case to the scanned pass to keep extra info.
            context.commit('SET_VERIFIER_SCANNED_PASS', { ...ahaCase, ...passInfo });
          }
          return;
        case 404:
          context.commit('SET_VERIFIER_SCAN_ERROR', AHA_CASE_NOT_FOUND_ERROR_MSG);
          return;
        case 500:
          context.commit('SET_VERIFIER_SCAN_ERROR', SCAN_SERVER_ERROR_MSG);
          return;
        default:
          //Log the error.
          context.commit('SET_VERIFIER_SCAN_ERROR', SCAN_UNKOWN_OTHER_ERROR_MSG);
          // eslint-disable-next-line no-console
          console.error(error);
      }
    } finally {
      context.commit('SET_VERIFIER_LOADING_PASS', false);
    }
  },

  async approveCurrentVisitor(context) {
    if (context.state.verifier.approvingVisitor) return; //Already approving visitor.
    try {
      context.commit('SET_VERIFIER_APPROVING_VISITOR', true);
      context.commit('SET_VERIFIER_APPROVE_VISITOR_ERROR', null);

      const pass = context.state.verifier?.scannedPass;
      const message = { id: pass?.id, reviewResponse: 'REVIEW_APPROVED' };

      const host = context.state.baseUrlApi;
      await axios.post(`${host}/healthcheck-restful-api/passengercard`, message);

      const editedPass = { ...pass, healthState: 'DVG_APPROVED' };
      context.commit('SET_VERIFIER_SCANNED_PASS', editedPass);
    } catch (error) {
      switch (error.response?.status) {
        case 403:
          context.commit('SET_VERIFIER_APPROVE_VISITOR_ERROR', APPROVAL_NOT_ALLOWED_ERROR_MSG);
          return;
        case 500:
          context.commit('SET_VERIFIER_APPROVE_VISITOR_ERROR', APPROVAL_SERVER_ERROR_MSG);
          return;
        default:
          context.commit('SET_VERIFIER_APPROVE_VISITOR_ERROR', APPROVAL_UNKOWN_OTHER_ERROR_MSG);
          // eslint-disable-next-line no-console
          console.error(error);
      }
    } finally {
      context.commit('SET_VERIFIER_APPROVING_VISITOR', false);
    }
  },

  logout(context) {
    //Use the utils with the current keycloak to logout.
    kcUtils.keycloak = context.state.keycloak.kc;
    kcUtils.logout();
    context.commit('SET_TOKEN', null);
    context.commit('SET_REFRESH_TOKEN', null);
    context.commit('SET_KC_EXPIRED_TOKEN_FN', null);
  },

  setTagEvent(context, tagEvent) {
    _setTagEventOnContext(context, tagEvent);
  },

  setTagEventLocation(context, location) {
    const tagEvent = context.state.selectedTagEvent;
    context.commit('SET_SELECTED_TAG_EVENT_LOCATION_ID', location?._id ?? null);
    if (tagEvent && location !== null) {
      context.commit('SET_VERIFIER_TAG', `${tagEvent?._id}@${location?._id}`);
      context.commit('SET_VERIFIER_TAG_TYPE', 'event');
      context.commit('SET_VERIFIER_TAG_DESCRIPTION', `${tagEvent?.name} @${location?.name}`);
    } else {
      //Revert to tag event only without location.
      _setTagEventOnContext(context, tagEvent);
    }
  },

  clearTag(context) {
    const type = context.state.verifier.tagType;
    if (type === 'event') {
      context.commit('SET_SELECTED_TAG_EVENT_LOCATION_ID', null);
      _setTagEventOnContext(context, null);
    }
  },

  async getUpcommingEvents(context) {
    if (context.state.loadingTagEvents) return; //Already loading tag events.
    try {
      context.commit('SET_LOADING_TAG_EVENTS', true);
      context.commit('SET_TAG_EVENTS_ERROR', null);

      const host = context.state.baseUrlApiCMS;
      const nowUTC = moment.utc().format('YYYY-MM-DDTHH:mm:ss[.000Z]');

      const userEmail = context.state.user.email;
      const query = qs.stringify({
        _where: [{ endDate_gte: nowUTC }, { allowedScanners_contains: userEmail }],
        _sort: 'startDate',
        visible: true
      });
      const config = { headers: { common: undefined } };
      const { data: tagEvents } = await axios.get(`${host}/events?${query}`, config);
      context.commit('SET_TAG_EVENTS', tagEvents);
    } catch (error) {
      context.commit('SET_TAG_EVENTS_ERROR', error);
      console.error(error);
    } finally {
      context.commit('SET_LOADING_TAG_EVENTS', false);
    }
  }
};
