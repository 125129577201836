var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manual-entry-container" },
    [
      _c("v-text-field", {
        ref: "inputField",
        attrs: {
          "data-testid": "pass-code-manual-entry-input",
          "append-icon": "mdi-qrcode-scan",
          outlined: "",
          placeholder: "Use scanner or enter pass ID",
          clearable: "",
          hint: _vm.hintMsg(),
          "persistent-hint": ""
        },
        on: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.onEnterPressed.apply(null, arguments)
          },
          keydown: _vm.onKeyDownAny,
          paste: function($event) {
            $event.preventDefault()
            return _vm.onPaste.apply(null, arguments)
          }
        },
        model: {
          value: _vm.passInfo,
          callback: function($$v) {
            _vm.passInfo = $$v
          },
          expression: "passInfo"
        }
      }),
      _vm.isScannerEntryInProgress
        ? _c("v-progress-linear", {
            staticClass: "progress",
            attrs: { "data-testid": "progress", indeterminate: "", height: "5" }
          })
        : _vm._e(),
      _c("v-divider")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }