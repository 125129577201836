var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { color: "white", app: "", fixed: "", dense: "" } },
    [
      _c("v-toolbar-title", [_c("Logo"), _vm._v(" Verify Pass ")], 1),
      _c("v-spacer"),
      _c(
        "v-btn",
        {
          attrs: { icon: "", color: "primary", "data-testid": "settings-btn" },
          on: { click: _vm.onClickSettings }
        },
        [_c("v-icon", [_vm._v("mdi-cog-outline")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }