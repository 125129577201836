var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "detail" }, [
        _vm._v("Tagging " + _vm._s(_vm.type) + ":")
      ]),
      _c(
        "v-chip",
        {
          staticClass: "ma-2",
          attrs: { color: "pink", label: "", "text-color": "white", close: "" },
          on: { "click:close": _vm.onClickClose }
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [_vm._v(" mdi-label ")]),
          _vm._v(" " + _vm._s(_vm.name) + " ")
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" Remove Tag? ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Are you sure you want to remove this " +
                    _vm._s(_vm.type) +
                    ' tag "' +
                    _vm._s(_vm.name) +
                    '"? '
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "darken-1",
                        text: "",
                        "data-testid": "remove-cancel"
                      },
                      on: { click: _vm.onCanceled }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "red darken-1",
                        text: "",
                        "data-testid": "remove-confirm"
                      },
                      on: { click: _vm.onConfirmed }
                    },
                    [_vm._v(" Remove ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }