<template>
  <div class="scanner-container">
    <!--Start Scanning Button-->
    <v-btn
      fab
      large
      color="primary"
      data-testid="start-button"
      v-if="!(scanning || restartScanningAfterResize)"
      v-on:click="startScanning"
      class="start-btn"
    >
      <v-icon>mdi-qrcode-scan</v-icon>
    </v-btn>

    <!--Scanning Dialog-->
    <v-dialog v-model="showDialog" eager fullscreen dark transition="dialog-bottom-transition" data-testid="dialog">
      <div class="scanner-dialog-contents">
        <!--Toolbar-->
        <v-toolbar dense flat class="toolbar" color="rgba(0, 0, 0, 0.8)">
          <v-toolbar-title> Scanning... </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn text @click="stopScanning" data-testid="cancel-button">
              Cancel
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <!--Scanner-->
        <div id="qr-code-scanner" class="scanner"></div>

        <!--Not Allowed Message-->
        <div v-if="notAllowed" class="not-allowed">
          <v-icon>mdi-video-off</v-icon>
          <span>No Permission to use Camera</span>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'PassCodeScanner',
  props: {
    stopScanningAfterSuccessfulScan: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      windowInnerWidth: null,
      loading: true,
      showDialog: false,
      scanner: null,
      scanning: false,
      notAllowed: false,
      reInitTimer: null,
      restartScanningAfterResize: false
    };
  },

  mounted() {
    const initialized = this.initScanner();
    //Still loading module if not initialized.
    this.loading = !initialized;
    this.windowInnerWidth = window.innerWidth;
    window.addEventListener('resize', this.onResize);
  },

  unmounted() {
    this.stopScanning();
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    initScanner() {
      if (this.scanner !== null) return; //Already init.
      const moduleAvailable = typeof window._.Html5Qrcode !== 'undefined';
      if (moduleAvailable) {
        this.scanner = new window._.Html5Qrcode('qr-code-scanner', { verbose: false });
        window._.scanner = this.scanner;
      }

      return moduleAvailable;
    },

    startScanning(onCompleteCallback) {
      if (this.scanner === null) return; //Not init.
      if (this.showDialog === true) return; //Dialog already shown.
      if (this.scanning === true) return; //Already scanning.

      const formatsToSupport = [
        window._.Html5QrcodeSupportedFormats.QR_CODE
        //Add other supported types here.
      ];

      this.showDialog = true;
      setTimeout(() => {
        this.scanner
          .start({ facingMode: 'environment' }, { fps: 10, formatsToSupport }, this.onSuccessfulScan)
          .then(() => this.setScanning(true))
          .catch(this.onStartScanningError)
          .finally(onCompleteCallback);
      }, 300);
    },

    destroyScanner() {
      if (this.scanner === null) return; //Not init.
      this.stopScanning();
      this.scanner = null;
    },

    stopScanning() {
      this.notAllowed = false; //Reset not allowed.

      if (this.showDialog === false) return; //Dialog already hidden.
      this.showDialog = false;

      if (this.scanning === false) return; //Already stopped scanning.
      this.setScanning(false);

      if (this.scanner === null) return; //Not initialized.
      this.scanner.stop();
      this.scanner.clear();
    },

    onStartScanningError(errorMsg) {
      this.setNotAllowed(errorMsg.includes('NotAllowedError'));
      if (!this.notAllowed) {
        //Then this is another error and we need to print it out.
        console.error(errorMsg);
      }
      this.setScanning(false);
    },

    onSuccessfulScan(data) {
      //Vibrate on successful scan of qr code to give user feedback (if supported).
      if (window.navigator?.vibrate) {
        window.navigator.vibrate(50);
      }
      this.$emit('successful-scan', data);
      if (this.stopScanningAfterSuccessfulScan) {
        this.stopScanning();
      }
    },

    onResize() {
      if (this.windowInnerWidth === window.innerWidth) return; //Inner width hasn't changed so ratio stays the same.
      this.windowInnerWidth = window.innerWidth;

      this.restartScanningAfterResize = this.scanning;
      this.destroyScanner();

      //Re initialize the timer only after resizing done.
      //Only last timeout will get called.
      if (this.reInitTimer) {
        clearTimeout(this.reInitTimer);
      }
      this.reInitTimer = setTimeout(() => {
        this.initScanner();
        if (this.restartScanningAfterResize) {
          this.startScanning(
            () =>
              //Note: Reset the restartScanningAfterResize after restart.
              (this.restartScanningAfterResize = false)
          );
        }
      }, 300);
    },

    setScanning(scanning) {
      this.scanning = scanning;
      this.$emit('scanning', this.scanning);
    },

    setNotAllowed(notAllowed) {
      this.notAllowed = notAllowed;
      this.$emit('not-allowed', this.notAllowed);
    }
  }
};
</script>

<style lang="scss">
.scanner-dialog-contents {
  width: 100%;
  height: 100%;
  background-color: black;
  position: relative;

  .toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .scanner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    video {
      max-height: 100%;
    }
  }
  .not-allowed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: white;

    .v-icon {
      flex: 0;
      color: inherit;
      font-size: 40px;
    }

    span {
      text-align: center;
    }
  }
}

.scanner-container {
  .start-btn {
    position: fixed;
    bottom: 10px;
    right: 20px;
    margin: 0 0 16px 16px;
    z-index: 1;
  }

  .stop-btn {
    position: fixed;
    bottom: 0px;
    color: white;
    font-size: 12px;
    height: 35px; //Set enough button height for mobile taps.
    margin-bottom: -7px; //Adjust due to large height
    .v-icon {
      right: 5px;
      color: inherit;
      font-size: 16px;
    }
  }
}
</style>
