<template>
  <v-app-bar color="white" app fixed dense>
    <v-toolbar-title>
      <Logo />
      Verify Pass
    </v-toolbar-title>
    <v-spacer />
    <v-btn icon color="primary" @click="onClickSettings" data-testid="settings-btn">
      <v-icon>mdi-cog-outline</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import Logo from '@/components/logo.vue';

export default {
  name: 'TopBar',

  components: {
    Logo
  },

  methods: {
    onClickSettings() {
      this.$router.push('/settings');
    }
  }
};
</script>
