import Keycloak from 'keycloak-js';

let initOptions = {
  url: 'https://api.dvgapp.org/auth/',
  realm: 'DVG',
  clientId: 'verifier-app',
  onLoad: 'login-required',
  scope: 'offline_access'
};

export default {
  keycloak: Keycloak(initOptions),

  _refreshTokensTimout: null,

  _loadAuthTokensFromStorage() {
    let token = localStorage.getItem('dashboard-dvg-kc-token'); //offline_token
    let refreshToken = localStorage.getItem('dashboard-dvg-kc-refresh-token'); //offline_token

    if (token === 'undefined' || refreshToken === 'undefined') {
      localStorage.removeItem('dashboard-dvg-kc-token');
      localStorage.removeItem('dashboard-dvg-kc-refresh-token');
      token = null;
      refreshToken = null;
    }
    return { token, refreshToken };
  },

  _storeAuthTokensInStorage() {
    localStorage.setItem('dashboard-dvg-kc-token', this.keycloak.token);
    localStorage.setItem('dashboard-dvg-kc-refresh-token', this.keycloak.refreshToken);
  },

  _getTokenRealmAccessRoles() {
    if (this.keycloak.realmAccess && this.keycloak.realmAccess.roles && this.keycloak.realmAccess.roles.length > 0) {
      //Apply roles to user.
      return this.keycloak.realmAccess.roles;
    } else {
      return [];
    }
  },

  async setup(options) {
    const { autoLogin = false, loadUserInfo = false } = options || {};
    const { token, refreshToken } = this._loadAuthTokensFromStorage();

    const auth = await this.keycloak.init({
      onLoad: 'check-sso',
      checkLoginIframe: false,
      token: token,
      refreshToken: refreshToken,
      pkceMethod: 'S256'
    });

    if (auth) {
      console.log('Authenticated');
    } else if (autoLogin === true) {
      this.keycloak.login();
    }

    let user = undefined;
    if (loadUserInfo) {
      user = await this.keycloak.loadUserInfo();
      user.roles = this._getTokenRealmAccessRoles();
    }

    this.logTokenValidity();

    this._storeAuthTokensInStorage();

    return {
      keycloak: this.keycloak,
      user
    };
  },

  startRefreshingTokensOnInterval(interval = 60000) {
    if (this._refreshTokensTimout) return; //Already active.
    this._refreshTokensTimout = setInterval(() => {
      this.keycloak
        .updateToken(50)
        .then((refreshed) => {
          if (refreshed) {
            console.log('Token refreshed ' + refreshed);
            this.setKeycloakTokens(this.keycloak);
          } else {
            this.logTokenValidity();
          }
        })
        .catch((e) => {
          console.error(e);
          console.error('Failed to refresh token');
          window.location.reload();
        });
    }, interval);
  },

  logTokenValidity() {
    if (this.keycloak?.tokenParsed?.exp) {
      console.log(
        'Token not refreshed, valid for ' +
          Math.round(this.keycloak?.tokenParsed?.exp + this.keycloak?.timeSkew - new Date().getTime() / 1000) +
          ' seconds'
      );
    } else {
      console.log('Token not refreshed and no valid token available');
    }
  },

  stopRefreshingTokensOnInterval() {
    if (!this._refreshTokensTimout) return; //Not active.
    clearInterval(this._refreshTokensTimout);
  },

  logout() {
    if (this.keycloak) {
      //Clear our auth tokens.
      localStorage.removeItem('dashboard-dvg-kc-token');
      localStorage.removeItem('dashboard-dvg-kc-refresh-token');
      //Then logout.
      this.keycloak.logout();
    }
  }
};
