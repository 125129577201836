<template>
  <v-card data-testid="pass-code-info" class="pass-code-info-container" max-width="500px" elevation="8">
    <div :class="`status-info ${statusColor}`">
      <v-icon color="#FFFFFF">
        {{ statusIcon }}
      </v-icon>
      <div class="text-container">
        <v-card-title id="pass-status" :class="`${statusColor} text-h4`">{{ statusTitle }}</v-card-title>
        <v-card-subtitle id="pass-status-description" :class="`${statusColor}`">{{ statusSubtitle }}</v-card-subtitle>
      </div>
    </div>
    <template v-if="showExtraPassInfo">
      <v-divider></v-divider>
      <v-container>
        <v-row no-gutters>
          <v-col sm="6">
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-icon>mdi-calendar</v-icon>
                Arrival Date
              </v-list-item-subtitle>
              <v-skeleton-loader v-if="hidePassPersonalInfo" boilerplate type="text" max-width="100" />
              <v-list-item-title v-else id="pass-arrival-date">{{ arrivalDate }}</v-list-item-title>
            </v-list-item-content>
          </v-col>
          <v-col sm="6">
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-icon>mdi-airplane-landing</v-icon>
                Flight Number
              </v-list-item-subtitle>
              <v-skeleton-loader v-if="hidePassPersonalInfo" boilerplate type="text" max-width="70" />
              <v-list-item-title v-else id="pass-flight-number">{{ flightNumber }}</v-list-item-title>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-if="showBasicPassInfo">
      <v-divider></v-divider>
      <v-container>
        <v-row no-gutters>
          <v-col sm="12">
            <v-list-item-content>
              <v-list-item-subtitle>Name</v-list-item-subtitle>
              <v-skeleton-loader v-if="hidePassPersonalInfo" boilerplate type="text" max-width="200" />
              <v-list-item-title v-else id="pass-full-name">{{ fullName }}</v-list-item-title>
            </v-list-item-content>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col sm="6">
            <v-list-item-content>
              <v-list-item-subtitle>Date of Birth</v-list-item-subtitle>
              <v-skeleton-loader v-if="hidePassPersonalInfo" boilerplate type="text" max-width="100" />
              <v-list-item-title v-else id="pass-dob">{{ dateOfBirth }}</v-list-item-title>
            </v-list-item-content>
          </v-col>
          <v-col sm="6">
            <v-list-item-content>
              <v-list-item-subtitle>Country</v-list-item-subtitle>
              <v-skeleton-loader v-if="hidePassPersonalInfo" boilerplate type="text" max-width="120" />
              <v-list-item-title v-else id="pass-country">{{ country }}</v-list-item-title>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else>
      <div class="no-personal-info-container">
        <span>{{ noPersonalInfoMsg }}</span>
      </div>
    </template>
    <template v-if="showApprovalOption">
      <v-divider></v-divider>
      <v-dialog v-model="showApprovalConfirmDialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-container class="approve-button-container">
            <v-btn color="success" large class="approve-btn" v-on="on" v-bind="attrs">
              <v-icon left>mdi-thumb-up</v-icon> {{ showApprovingVisitor ? 'Approving...' : 'Approve' }}
            </v-btn>
            <v-alert dense outlined type="error" v-if="approveVisitorError">
              {{ approveVisitorError }}
            </v-alert>
          </v-container>
        </template>

        <v-card class="approval-confirm-container">
          <v-card-title class="text-h5 warning lighten-2">
            Confirm Test Approval
          </v-card-title>
          <v-card-text> Are you sure you want to approve the test of {{ fullName }}? </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showApprovalConfirmDialog = false">
              Cancel
            </v-btn>
            <v-btn color="error" text @click="onApprove" data-testid="confirm-approve">
              Approve
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-card>
</template>

<script>
import moment from 'moment';

export const NO_PERSONAL_INFO_MSG = 'No personal information available';
export const STATUS_INFO = {
  TEST_NEEDED: {
    color: 'deny',
    icon: 'mdi-minus-circle',
    title: 'Must Review Test',
    subtitle: 'Review at Airport'
  },
  TEST_IN_PROGRESS: {
    color: 'inspect',
    icon: 'mdi-minus-circle',
    title: 'Pending Test Results',
    subtitle: 'Test In Progress'
  },
  DVG_REVIEW: {
    color: 'deny',
    icon: 'mdi-minus-circle',
    title: 'Must Review Test',
    subtitle: 'In Review DVG'
  },
  DVG_APPROVED: {
    color: 'pass',
    icon: 'mdi-check-circle',
    title: 'Pass Allowed',
    subtitle: 'DVG Approved'
  },
  DVG_DENIED: {
    color: 'deny',
    icon: 'mdi-minus-circle',
    title: 'Must Review Test',
    subtitle: 'DVG Denied'
  },
  COMMONPASS_APPROVED: {
    color: 'pass',
    icon: 'mdi-check-circle',
    title: 'Pass Allowed',
    subtitle: 'CommonPass Approved'
  },
  FULLY_VACCINATED: {
    color: 'pass',
    icon: 'mdi-check-circle',
    title: 'Vaccinated',
    subtitle: 'Person is fully vaccinated'
  },
  NOT_FULLY_VACCINATED: {
    color: 'inspect',
    icon: 'mdi-alert-circle',
    title: 'Not Vaccinated',
    subtitle: 'Person is not fully vaccinated'
  }
};
export default {
  name: 'PassCodeInfo',

  props: {
    pass: {
      type: Object,
      default: () => ({
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        countryResidency: null,
        healthState: -1,
        id: null
      })
    },
    isAllowedToApprove: {
      type: Boolean,
      default: false
    },
    showApprovingVisitor: {
      type: Boolean,
      default: false
    },
    approveVisitorError: {
      type: String,
      default: null
    },
    hidePassPersonalInfo: {
      type: Boolean,
      default: false
    },
    hideBasicDetails: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showApprovalConfirmDialog: false,
      noPersonalInfoMsg: NO_PERSONAL_INFO_MSG
    };
  },

  computed: {
    showExtraPassInfo() {
      if (this.hideBasicDetails === false) {
        return this.showBasicPassInfo && (this.arrivalDate || this.flightNumber);
      }
      return false;
    },
    showBasicPassInfo() {
      return this.pass?.firstName || this.pass?.lastName || this.pass?.dateOfBirth || this.pass?.countryResidency;
    },
    statusColor() {
      return STATUS_INFO[this.pass?.healthState]?.color || '';
    },

    statusIcon() {
      return STATUS_INFO[this.pass?.healthState]?.icon || '';
    },

    statusTitle() {
      return STATUS_INFO[this.pass?.healthState]?.title || '-';
    },

    statusSubtitle() {
      return STATUS_INFO[this.pass?.healthState]?.subtitle || '-';
    },

    arrivalDate() {
      if (this.pass?.arrivalDate) {
        return moment(this.pass.arrivalDate, 'M/D/YYYY').format('DD-MMM-YYYY');
      }
      return false;
    },

    flightNumber() {
      return this.pass?.flightNumber ? this.pass.flightNumber : false;
    },

    fullName() {
      const obscure = s => s.replace(/\B[a-z]/gi, '*');
      if (this.pass?.firstName || this.pass?.lastName) {
        const fullName = `${this.pass?.firstName || ''} ${this.pass?.lastName || ''}`.trim();
        return this.hideBasicDetails ? obscure(fullName) : fullName;
      }
      return '-';
    },

    dateOfBirth() {
      //FIX: Is now formatted by server.
      //Only pad the first two characters dd in the date.
      const obscure = s => s.replace(/^[1-9]{2}/, '**');
      if (this.pass?.dateOfBirth) {
        return this.hideBasicDetails ? obscure(this.pass?.dateOfBirth) : this.pass?.dateOfBirth;
      }
      return '-';
    },

    country() {
      if (this.pass?.countryResidency) {
        return this.pass?.countryResidency;
      }
      return '-';
    },

    showApprovalOption() {
      const approvalStates = ['TEST_NEEDED', 'DVG_REVIEW'];
      return this.isAllowedToApprove && approvalStates.includes(this.pass?.healthState);
    }
  },
  methods: {
    onApprove() {
      this.$emit('approve-visitor', this.pass?.id);
      this.showApprovalConfirmDialog = false;
    }
  }
};
</script>

<style lang="scss">
.pass-code-info-container {
  min-height: fit-content;

  .status-info {
    display: flex;
    justify-content: flex-start;
    padding: 10px;

    .v-icon {
      margin-right: 0px;
      font-size: 60px;
      text-shadow: 0 0 1px #004d1a;
    }

    .text-container {
      .v-card__title {
        font-weight: 700;
        color: white;

        padding: 12px;
        @media screen and (max-width: 400px) {
          font-size: 29px !important;
        }
        @media screen and (max-width: 320px) {
          font-size: 23px !important;
        }
      }
      .v-card__subtitle {
        font-weight: 500;
        color: white;
        padding: 12px;
        padding-top: 0px;
      }
    }

    .pass {
      text-shadow: 0 0 1px #004d1a;
    }

    .deny {
      text-shadow: 0 0 1px #4d0000;
    }

    .inspect {
      text-shadow: 0 0 1px #662200;
    }
  }

  .status-info.pass {
    background-color: #2a8000;
  }

  .status-info.deny {
    background-color: #e60000;
  }

  .status-info.inspect {
    background-color: #e64c00;
  }

  .v-list-item__subtitle {
    font-weight: 300;

    .v-icon {
      font-size: 14px;
    }
  }
  .v-list-item__title {
    font-weight: 500;
  }

  .approve-btn {
    width: 100%;
  }

  .v-skeleton-loader__text {
    height: 17.5px;
    margin-bottom: 0px;
  }

  .no-personal-info-container {
    width: 100%;
    height: 169px; //Rest of height.
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--v-secondary-lighten3);
  }
}

.approval-confirm-container {
  .v-card__title {
    margin-bottom: 16px;
  }
}

.approve-button-container {
  .v-alert {
    margin-top: 16px;
  }
}

.v-list-item__title {
  user-select: all;
}
</style>
