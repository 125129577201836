export const parseAHAFlightInfoFromData = data => {
  if (!data || data?.length === 0) return null;
  if (typeof data !== 'string') return null;

  //Replace any new lines or carriage returns (for scanner).
  data = data.replace(/[\r\n]/gi, '').trim();
  if (!(data.startsWith('[[') && data.endsWith(']]'))) return null;

  //Confirm this is aha flight data.
  const content = data.slice(2, -2);
  if (content.length === 0) return null;

  try {
    const jsonStr = atob(content);
    const ahaFlight = JSON.parse(jsonStr);
    return ahaFlight;
  } catch (error) {
    console.log(error);
    return null;
  }
};
