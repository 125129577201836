<template>
  <div id="verifier" data-testid="verifier" :class="inputType">
    <TopBar />
    <v-main>
      <scanner-container :inputType="inputType">
        <template v-slot:camera>
          <PassCodeScanner @scanning="onScanning" @not-allowed="onNotAllowed" @successful-scan="onProcessPass" />
        </template>
        <template v-slot:manual>
          <PassCodeManualEntry @entry-confirmed="onProcessPass" ref="manualEntry" />
        </template>
      </scanner-container>
      <LastScanned
        :scanType="scanType"
        :inputType="inputType"
        :scanning="scanning"
        :notAllowed="notAllowed"
        :loadingPass="loadingPass"
        :pass="scannedPass"
        :hideTag="hideTag"
        :tagName="tagName"
        :tagType="tagType"
        :showApprovingVisitor="approvingVisitor"
        :approveVisitorError="approveVisitorError"
        :isAllowedToApprove="userAllowedToApprove"
        :hidePassPersonalInfo="hidePassPersonalInfo"
        :hideBasicDetails="!userIsAllowedToViewBasicDetails"
        @clear-pass="onClearPass"
        @approve-visitor="onApproveVisitor"
        @remove-tag="onRemoveTag"
      />
      <ScanError :show="showError" @dismiss="onDismissError" :errorMessage="errorMessage" />
    </v-main>
  </div>
</template>

<script>
import PassCodeScanner from '@/components/pass-code-scanner.vue';
import PassCodeManualEntry from '@/components/pass-code-manual-entry.vue';
import LastScanned from './components/last-scanned.vue';
import ScannerContainer from './components/scanner-container.vue';
import { mapState } from 'vuex';
import { parseEdCardInfoFromData } from '@/utils/edcard';
import { parseAHAFlightInfoFromData } from '@/utils/aha-flight';
import { parseAHACaseInfoFromData } from '@/utils/aha-case';
import { SCAN_TYPE_LOCALS_VACCINATED } from '@/utils/type-formats';
import TopBar from './components/top-bar.vue';
import ScanError from './components/scan-error.vue';
import { userAllowedToApprove, userIsAllowedToViewBasicDetails } from '@/utils/permissions';

export default {
  name: 'Verifier',

  components: {
    TopBar,
    PassCodeScanner,
    PassCodeManualEntry,
    LastScanned,
    ScannerContainer,
    ScanError
  },

  data: () => ({
    showError: false,
    errorMessage: null
  }),

  computed: {
    ...mapState({
      scanType: state => state?.verifier?.scanType,
      inputType: state => state?.verifier?.inputType,
      scannedPass: state => state?.verifier?.scannedPass,
      scanning: state => state?.verifier?.scanning,
      notAllowed: state => state?.verifier?.notAllowed,
      loadingPass: state => state?.verifier?.loadingPass,
      scanError: state => state?.verifier?.scanError,
      hideTag: state => state?.verifier?.scanType !== SCAN_TYPE_LOCALS_VACCINATED,
      tagName: state => state?.verifier?.tagDescription,
      tagType: state => state?.verifier?.tagType,
      approvingVisitor: state => state?.verifier?.approvingVisitor,
      approveVisitorError: state => state?.verifier?.approveVisitorError,
      userAllowedToApprove: s => userAllowedToApprove(s.user),
      userIsAllowedToViewBasicDetails: s => userIsAllowedToViewBasicDetails(s.user),
      hidePassPersonalInfo: s => {
        if (userIsAllowedToViewBasicDetails(s.user)) {
          return s.hidePassPersonalInfo;
        } else false; //Already hiding basic details.
      }
    })
  },

  watch: {
    scanError(newValue) {
      this.showError = newValue === null ? false : true;
      this.errorMessage = newValue === null ? null : newValue;
    }
  },

  methods: {
    onProcessPass(data) {
      switch (this.scanType) {
        case 'incomming-visitors': {
          return this.onProcessIncommingVisitorPass(data);
        }
        default: {
          return this.onProcessLocalVacinatedPass(data);
        }
      }
    },

    async onProcessIncommingVisitorPass(data) {
      const edCardInfo = parseEdCardInfoFromData(data);
      const ahaFlightInfo = parseAHAFlightInfoFromData(data);
      if (edCardInfo) {
        await this.$store.dispatch('getPassCodeInfoFromEdCard', edCardInfo);
      } else if (ahaFlightInfo) {
        await this.$store.dispatch('getPassCodeInfoFromAhaFlight', ahaFlightInfo);
      } else {
        this.showError = true;
        this.errorMessage = 'Pass type not supported. Only AHA Flight or EDCard QR-Codes can be scanned when scanning for "Incomming Visitors".';
      }
    },

    async onProcessLocalVacinatedPass(data) {
      const ahaCaseInfo = parseAHACaseInfoFromData(data);
      if (ahaCaseInfo) {
        await this.$store.dispatch('getPassCodeInfoFromAhaCase', ahaCaseInfo);
      } else {
        this.showError = true;
        this.errorMessage = 'Pass type not supported. Only AHA Case QR-Codes can be scanned when scanning for "Locals Vacinated".';
      }
    },

    onNotAllowed(notAllowed) {
      this.$store.commit('SET_VERIFIER_NOT_ALLOWED', notAllowed);
    },

    onScanning(scanning) {
      this.$store.commit('SET_VERIFIER_SCANNING_PASS', scanning);
    },

    onClearPass() {
      this.$store.commit('SET_VERIFIER_SCANNED_PASS', null);
    },

    onApproveVisitor() {
      this.$store.dispatch('approveCurrentVisitor');
      this.$nextTick(this.focusManualInput);
    },

    onRemoveTag() {
      this.$store.dispatch('clearTag', this.tagType);
      this.$nextTick(this.focusManualInput);
    },

    onDismissError() {
      this.showError = false;
      this.errorMessage = null;
    },

    focusManualInput() {
      this.$refs.manualEntry?.focus();
    }
  }
};
</script>
<style lang="scss">
#verifier {
  padding-bottom: env(safe-area-inset-bottom);
}
#verifier.camera {
  @media screen and (max-width: 650px) {
    margin-bottom: 84px; //Add room for the scan bottom at the bottom.
  }
}
</style>
