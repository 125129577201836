var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "scanner-wrapper-container" },
    [
      _vm.inputType === "camera"
        ? _vm._t("camera", function() {
            return [_vm._v("No Scanner")]
          })
        : _vm._e(),
      _vm.inputType === "manual"
        ? _vm._t("manual", function() {
            return [_vm._v("No Manual Entry")]
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }