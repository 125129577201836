var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "pass-code-info-container",
      attrs: {
        "data-testid": "pass-code-info",
        "max-width": "500px",
        elevation: "8"
      }
    },
    [
      _c(
        "div",
        { class: "status-info " + _vm.statusColor },
        [
          _c("v-icon", { attrs: { color: "#FFFFFF" } }, [
            _vm._v(" " + _vm._s(_vm.statusIcon) + " ")
          ]),
          _c(
            "div",
            { staticClass: "text-container" },
            [
              _c(
                "v-card-title",
                {
                  class: _vm.statusColor + " text-h4",
                  attrs: { id: "pass-status" }
                },
                [_vm._v(_vm._s(_vm.statusTitle))]
              ),
              _c(
                "v-card-subtitle",
                {
                  class: "" + _vm.statusColor,
                  attrs: { id: "pass-status-description" }
                },
                [_vm._v(_vm._s(_vm.statusSubtitle))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showExtraPassInfo
        ? [
            _c("v-divider"),
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { sm: "6" } },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-subtitle",
                              [
                                _c("v-icon", [_vm._v("mdi-calendar")]),
                                _vm._v(" Arrival Date ")
                              ],
                              1
                            ),
                            _vm.hidePassPersonalInfo
                              ? _c("v-skeleton-loader", {
                                  attrs: {
                                    boilerplate: "",
                                    type: "text",
                                    "max-width": "100"
                                  }
                                })
                              : _c(
                                  "v-list-item-title",
                                  { attrs: { id: "pass-arrival-date" } },
                                  [_vm._v(_vm._s(_vm.arrivalDate))]
                                )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { sm: "6" } },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-subtitle",
                              [
                                _c("v-icon", [_vm._v("mdi-airplane-landing")]),
                                _vm._v(" Flight Number ")
                              ],
                              1
                            ),
                            _vm.hidePassPersonalInfo
                              ? _c("v-skeleton-loader", {
                                  attrs: {
                                    boilerplate: "",
                                    type: "text",
                                    "max-width": "70"
                                  }
                                })
                              : _c(
                                  "v-list-item-title",
                                  { attrs: { id: "pass-flight-number" } },
                                  [_vm._v(_vm._s(_vm.flightNumber))]
                                )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.showBasicPassInfo
        ? [
            _c("v-divider"),
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { sm: "12" } },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-subtitle", [_vm._v("Name")]),
                            _vm.hidePassPersonalInfo
                              ? _c("v-skeleton-loader", {
                                  attrs: {
                                    boilerplate: "",
                                    type: "text",
                                    "max-width": "200"
                                  }
                                })
                              : _c(
                                  "v-list-item-title",
                                  { attrs: { id: "pass-full-name" } },
                                  [_vm._v(_vm._s(_vm.fullName))]
                                )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { sm: "6" } },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-subtitle", [
                              _vm._v("Date of Birth")
                            ]),
                            _vm.hidePassPersonalInfo
                              ? _c("v-skeleton-loader", {
                                  attrs: {
                                    boilerplate: "",
                                    type: "text",
                                    "max-width": "100"
                                  }
                                })
                              : _c(
                                  "v-list-item-title",
                                  { attrs: { id: "pass-dob" } },
                                  [_vm._v(_vm._s(_vm.dateOfBirth))]
                                )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { sm: "6" } },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-subtitle", [_vm._v("Country")]),
                            _vm.hidePassPersonalInfo
                              ? _c("v-skeleton-loader", {
                                  attrs: {
                                    boilerplate: "",
                                    type: "text",
                                    "max-width": "120"
                                  }
                                })
                              : _c(
                                  "v-list-item-title",
                                  { attrs: { id: "pass-country" } },
                                  [_vm._v(_vm._s(_vm.country))]
                                )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : [
            _c("div", { staticClass: "no-personal-info-container" }, [
              _c("span", [_vm._v(_vm._s(_vm.noPersonalInfoMsg))])
            ])
          ],
      _vm.showApprovalOption
        ? [
            _c("v-divider"),
            _c(
              "v-dialog",
              {
                attrs: { width: "500" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-container",
                            { staticClass: "approve-button-container" },
                            [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "approve-btn",
                                      attrs: { color: "success", large: "" }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-thumb-up")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.showApprovingVisitor
                                          ? "Approving..."
                                          : "Approve"
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              ),
                              _vm.approveVisitorError
                                ? _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        dense: "",
                                        outlined: "",
                                        type: "error"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.approveVisitorError) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3580984209
                ),
                model: {
                  value: _vm.showApprovalConfirmDialog,
                  callback: function($$v) {
                    _vm.showApprovalConfirmDialog = $$v
                  },
                  expression: "showApprovalConfirmDialog"
                }
              },
              [
                _c(
                  "v-card",
                  { staticClass: "approval-confirm-container" },
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "text-h5 warning lighten-2" },
                      [_vm._v(" Confirm Test Approval ")]
                    ),
                    _c("v-card-text", [
                      _vm._v(
                        " Are you sure you want to approve the test of " +
                          _vm._s(_vm.fullName) +
                          "? "
                      )
                    ]),
                    _c("v-divider"),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: {
                              click: function($event) {
                                _vm.showApprovalConfirmDialog = false
                              }
                            }
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "error",
                              text: "",
                              "data-testid": "confirm-approve"
                            },
                            on: { click: _vm.onApprove }
                          },
                          [_vm._v(" Approve ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }