var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "last-scanned-container", attrs: { "max-width": "500px" } },
    [
      _vm.pass
        ? [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "header", attrs: { sm: "12" } },
                  [
                    _c(
                      "span",
                      { staticClass: "text-h6" },
                      [
                        _vm._v(" Scanned Pass "),
                        _vm.hidePassPersonalInfo
                          ? _c(
                              "v-icon",
                              {
                                attrs: { "data-testid": "privacy-btn" },
                                on: {
                                  click: function($event) {
                                    _vm.ignoreHidePassPersonalInfo = !_vm.ignoreHidePassPersonalInfo
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.ignoreHidePassPersonalInfo
                                        ? "mdi-eye"
                                        : "mdi-eye-off"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      { attrs: { text: "" }, on: { click: _vm.onClear } },
                      [_vm._v(" Clear ")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-divider")
          ]
        : _vm._e(),
      _vm.loadingPass
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pass-progress", attrs: { sm: "12" } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm.pass
        ? _c("PassCodeInfo", {
            attrs: {
              pass: _vm.pass,
              showApprovingVisitor: _vm.showApprovingVisitor,
              approveVisitorError: _vm.approveVisitorError,
              isAllowedToApprove: _vm.isAllowedToApprove,
              hidePassPersonalInfo: _vm.ignoreHidePassPersonalInfo
                ? false
                : _vm.hidePassPersonalInfo,
              hideBasicDetails: _vm.hideBasicDetails
            },
            on: { "approve-visitor": _vm.onApproveVisitor }
          })
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                { class: "pass-info " + _vm.inputType, attrs: { sm: "12" } },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v(_vm._s(_vm.scanTypeIcon))
                  ]),
                  _c("span", { staticClass: "text-caption" }, [
                    _vm._v(" " + _vm._s(_vm.scanTypeDescription) + " ")
                  ]),
                  _c("span", { staticClass: "text-subtitle-2" }, [
                    _vm._v(" No Passes Scanned ")
                  ]),
                  _c("span", { staticClass: "text-caption" }, [
                    _vm._v(" " + _vm._s(_vm.scannerStatus) + " ")
                  ]),
                  !_vm.hideTag && _vm.tagName
                    ? _c("Tag", {
                        attrs: { name: _vm.tagName, type: _vm.tagType },
                        on: { remove: _vm.onRemoveTag }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }