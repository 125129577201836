<template>
  <div>
    <div class="detail">Tagging {{ type }}:</div>
    <v-chip class="ma-2" color="pink" label text-color="white" close @click:close="onClickClose">
      <v-icon left>
        mdi-label
      </v-icon>
      {{ name }}
    </v-chip>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Remove Tag?
        </v-card-title>
        <v-card-text> Are you sure you want to remove this {{ type }} tag "{{ name }}"? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="onCanceled" data-testid="remove-cancel">
            Cancel
          </v-btn>
          <v-btn color="red darken-1" text @click="onConfirmed" data-testid="remove-confirm">
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'Tag',

  props: {
    type: String,
    name: String
  },

  data() {
    return {
      dialog: false
    };
  },

  methods: {
    onClickClose() {
      this.dialog = true;
    },

    onConfirmed() {
      this.$emit('remove');
      this.dialog = false;
    },

    onCanceled() {
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss">
.detail {
  margin-top: 15px;
  font-size: 9px;
}
</style>
