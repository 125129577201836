export const SCAN_TYPE_INCOMMING_VISITORS = 'incomming-visitors';
export const SCAN_TYPE_LOCALS_VACCINATED = 'locals-vaccinated';
export const SCAN_TYPES = [SCAN_TYPE_INCOMMING_VISITORS, SCAN_TYPE_LOCALS_VACCINATED];
export const SCAN_TYPES_DESCRIPTIONS = ['Incomming Visitors', 'Locals Vacinated'];

export const scanTypeToDescription = scanType => {
  const index = SCAN_TYPES.findIndex(t => t === scanType);
  return index >= 0 ? SCAN_TYPES_DESCRIPTIONS[index] : null;
};

export const scanTypeFromDescription = scanTypeDescription => {
  const index = SCAN_TYPES_DESCRIPTIONS.findIndex(d => d === scanTypeDescription);
  return index >= 0 ? SCAN_TYPES[index] : null;
};

export const INPUT_TYPES = ['manual', 'camera'];
export const INPUT_TYPES_DESCRIPTIONS = ['Manual Input (Scanner)', 'Camera'];

export const inputTypeToDescription = inputType => {
  const index = INPUT_TYPES.findIndex(t => t === inputType);
  return index >= 0 ? INPUT_TYPES_DESCRIPTIONS[index] : null;
};

export const inputTypeFromDescription = inputTypeDescription => {
  const index = INPUT_TYPES_DESCRIPTIONS.findIndex(d => d === inputTypeDescription);
  return index >= 0 ? INPUT_TYPES[index] : null;
};
