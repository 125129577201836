export default {
  //generic

  SET_USER(state, payload) {
    state.user = payload;
  },

  SET_TOKEN(state, payload) {
    state.keycloak.token = payload;
  },

  SET_REFRESH_TOKEN(state, payload) {
    state.keycloak.refreshToken = payload;
  },

  SET_KEYCLOAK_OBJECT(state, payload) {
    state.keycloak.kc = payload;
  },

  SET_KC_EXPIRED_TOKEN_FN(state, value) {
    state.isTokenExpiredFn = value;
  },

  SET_HIDE_PASS_PERSONAL_INFO(state, value) {
    state.hidePassPersonalInfo = value;
  },

  SET_LOADING_TAG_EVENTS(state, value) {
    state.loadingTagEvents = Boolean(value);
  },

  SET_TAG_EVENTS_ERROR(state, value) {
    state.tagEventsError = value;
  },

  SET_TAG_EVENTS(state, value = []) {
    state.tagEvents = value;
  },

  SET_SELECTED_TAG_EVENT(state, value = {}) {
    state.selectedTagEvent = value;
  },

  SET_SELECTED_TAG_EVENT_LOCATION_ID(state, value = {}) {
    state.selectedTagEventLocationID = value;
  },

  //Verfifier
  SET_VERIFIER_SCAN_TYPE(state, value) {
    if (!['incomming-visitors', 'locals-vaccinated'].includes(value)) {
      throw new Error('Invalid verifier scan type');
    }
    state.verifier = { ...state.verifier, scanType: value };
  },

  SET_VERIFIER_INPUT_TYPE(state, value) {
    if (!['camera', 'manual'].includes(value)) {
      throw new Error('Invalid verifier input type');
    }
    const scanning = value === 'manual' ? false : state.verifier.scanning;
    state.verifier = { ...state.verifier, inputType: value, scanning };
  },

  SET_VERIFIER_SCANNED_PASS(state, value) {
    state.verifier = { ...state.verifier, scannedPass: value };
  },

  SET_VERIFIER_SCANNING_PASS(state, value) {
    state.verifier = { ...state.verifier, scanning: value };
  },

  SET_VERIFIER_NOT_ALLOWED(state, value) {
    state.verifier = { ...state.verifier, notAllowed: value };
  },

  SET_VERIFIER_LOADING_PASS(state, value) {
    state.verifier = { ...state.verifier, loadingPass: value };
  },

  SET_VERIFIER_SCAN_ERROR(state, value) {
    state.verifier = { ...state.verifier, scanError: value };
  },

  SET_VERIFIER_APPROVING_VISITOR(state, value) {
    state.verifier = { ...state.verifier, approvingVisitor: value };
  },

  SET_VERIFIER_APPROVE_VISITOR_ERROR(state, value) {
    state.verifier = { ...state.verifier, approveVisitorError: value };
  },

  SET_VERIFIER_TAG(state, value) {
    state.verifier = { ...state.verifier, tag: value };
  },

  SET_VERIFIER_TAG_TYPE(state, value) {
    state.verifier = { ...state.verifier, tagType: value };
  },

  SET_VERIFIER_TAG_DESCRIPTION(state, value) {
    state.verifier = { ...state.verifier, tagDescription: value };
  }
};
