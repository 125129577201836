<template>
  <v-app data-testid="app">
    <router-view></router-view>
    <v-snackbar data-testid="update-available" bottom right :value="updateExists" :timeout="-1" color="primary">
      An update is available
      <v-btn text @click="refreshApp"> Update </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import update from '@/mixins/update';

export default {
  name: 'App',
  mixins: [update]
};
</script>

<style lang="scss">
body {
  background-color: #ffffff;
}

html,
body,
.v-application,
.v-application--wrap {
  //Fix:  Fill the availble space instead of using 100vh (default in vuetify).
  //      This resolves the issue that an empty screen scrolls in iOS safari.
  min-height: 100vh;
  min-height: -webkit-fill-available !important;
  user-select: none;
}
</style>
