var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "logo",
      attrs: {
        width: "107",
        height: "107",
        viewBox: "0 0 107 107",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("circle", {
        attrs: {
          cx: "53.5",
          cy: "53.5",
          r: "53.5",
          fill: "url(#paint0_linear)"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M51.4215 79.2983C50.7627 80.0235 49.8643 80.4285 48.9307 80.4285C47.9971 80.4285 47.0987 80.0235 46.4399 79.2983L26.5484 57.5142C24.4839 55.2537 24.4839 51.5889 26.5484 49.3322L29.0392 46.6048C31.1038 44.3443 34.4472 44.3443 36.5117 46.6048L48.9307 60.203L74.5275 35.6954C76.592 33.4348 79.939 33.4348 82 35.6954L84.4908 38.4227C86.5554 40.6833 86.5554 44.3481 84.4908 46.6048L51.4215 79.2983Z",
          fill: "white"
        }
      }),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear",
                x1: "21.5",
                y1: "96",
                x2: "115.5",
                y2: "-10.5",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#4075D4" } }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#40B9D4" } })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }