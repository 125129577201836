var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { "data-testid": "app" } },
    [
      _c("router-view"),
      _c(
        "v-snackbar",
        {
          attrs: {
            "data-testid": "update-available",
            bottom: "",
            right: "",
            value: _vm.updateExists,
            timeout: -1,
            color: "primary"
          }
        },
        [
          _vm._v(" An update is available "),
          _c("v-btn", { attrs: { text: "" }, on: { click: _vm.refreshApp } }, [
            _vm._v(" Update ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }