import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import _mutations from './mutations';
import _actions from './actions';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations: _mutations,
  actions: _actions
});

export const mutations = _mutations;
export const actions = _actions;
