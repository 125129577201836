const clean = value => value?.replace(/(\r\n|\n|\r)/gm, '').trim();

export const parseEdCardInfoFromData = data => {
  if (!data || data?.length === 0) return null;
  if (typeof data !== 'string') return null;

  //Check if one part for manual entry.
  let parts = data?.split(/\r\n/);
  if (parts.length === 1 && parts[0].length <= 10 && !isNaN(parts[0])) {
    //If it is a number below 11 characters it could be a edcard number.
    parts = [undefined, undefined, undefined, parts[0]];
  }
  //Remove last part if it is a empty string;
  if (parts.length === 5 && parts[4] === '') {
    parts = parts.slice(0, -1);
  }
  if (parts.length !== 4) return null;
  return {
    name: clean(parts[0]),
    arrivalDate: clean(parts[1]),
    flightNumber: clean(parts[2]),
    edNumber: clean(parts[3])
  };
};
