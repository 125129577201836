import { version } from '../../package';
import {
  loadHidePassPersonalInfoFromStorage,
  loadVerifierInputTypeFromStorage,
  loadVerifierScanTypeFromStorage,
  loadSelectedTagEventFromStorage,
  loadVerifierTagDescriptionFromStorage,
  loadVerifierTagFromStorage,
  loadVerifierTagTypeFromStorage,
  loadSelectedTagEventLocationIDFromStorage
} from '../utils/local-storage-marshalling';

const getVersion = () => {
  return process.env.VUE_APP_TAG_VERSION ? process.env.VUE_APP_TAG_VERSION : version || 'unkown version';
};

const getBuildnuber = () => {
  return process.env.VUE_APP_BUILD_NUMBER ? process.env.VUE_APP_BUILD_NUMBER : 'dev';
};

export default () => ({
  baseUrlApi: process.env.NODE_ENV === 'production' ? 'https://api.dvgapp.org' : 'https://test.dvgapp.org',
  baseUrlApiKC: 'https://api.dvgapp.org',
  baseUrlApiCMS: 'https://tourist.dvgapp.org',
  config: {
    appVersion: `${getVersion()} (${getBuildnuber()})`
  },
  keycloak: {
    token: null,
    refreshToken: null,
    kc: null
  },
  isTokenExpiredFn: null,
  user: null,
  hasRealmRoleFn: null,
  hasRoleTechLab: null,

  hidePassPersonalInfo: loadHidePassPersonalInfoFromStorage(),

  loadingTagEvents: false,
  tagEventsError: null,
  tagEvents: [],
  selectedTagEvent: loadSelectedTagEventFromStorage(),
  selectedTagEventLocationID: loadSelectedTagEventLocationIDFromStorage(),

  verifier: {
    scanType: loadVerifierScanTypeFromStorage(),
    inputType: loadVerifierInputTypeFromStorage(),
    scannedPass: null,
    scanning: false,
    notAllowed: false,
    loadingPass: false,
    scanError: null,
    approvingVisitor: false,
    approveVisitorError: null,
    tag: loadVerifierTagFromStorage(),
    tagType: loadVerifierTagTypeFromStorage(),
    tagDescription: loadVerifierTagDescriptionFromStorage()
  }
});
