export const parseAHACaseInfoFromData = data => {
  if (!data || data?.length === 0) return null;
  if (typeof data !== 'string') return null;

  //Replace any new lines or carriage returns (for scanner).
  data = data.replace(/[\r\n]/gi, '').trim();
  const regexUDID = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;
  const isUDID = regexUDID.test(data);
  if (!isUDID) {
    return null;
  }

  //Confirm this is aha flight data.
  const hasCircumflex = data.startsWith('^^') && data.endsWith('^');
  const ahaUDID = hasCircumflex ? data.slice(2, -1) : data;
  if (ahaUDID.length === 0) return null;

  try {
    return { udid: ahaUDID };
  } catch (error) {
    console.log(error);
    return null;
  }
};
