import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';
import kcUtils from './utils/keycloak';
import store from './store';
import router from './router';

require('@/store/subscribers');

Vue.config.productionTip = false;

kcUtils
  .setup({ autoLogin: true, loadUserInfo: true })
  .then(({ keycloak, user }) => {
    //Apply the user to the store
    store.commit('SET_USER', user);

    //Apply kc info to store.
    store.commit('SET_TOKEN', keycloak.token);
    store.commit('SET_REFRESH_TOKEN', keycloak.refreshToken);
    store.commit('SET_KC_EXPIRED_TOKEN_FN', keycloak.isTokenExpired);
    store.commit('SET_KEYCLOAK_OBJECT', keycloak);

    //Referesh the tokens on the default interval.
    kcUtils.startRefreshingTokensOnInterval();

    new Vue({
      vuetify,
      store,
      router,
      render: h => h(App)
    }).$mount('#app');
  })
  .catch(e => {
    //Todo display error.
    e && console.error(e);
  });
