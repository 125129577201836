import store from '@/store';
import axios from 'axios';
import {
  saveHidePassPersonalInfoToStorage,
  saveVerifierInputTypeToStorage,
  saveVerifierScanTypeToStorage,
  saveSelectedTagEventToStorage,
  saveVerifierTagToStorage,
  saveVerifierTagDescriptionToStorage,
  saveVerifierTagTypeToStorage,
  saveSelectedTagEventLocationIDToStorage
} from '../utils/local-storage-marshalling';

store.subscribe(mutation => {
  if (mutation.type === 'SET_TOKEN') {
    //Apply auth token change to axios's default headers.
    if (mutation.payload) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`;
      axios.defaults.headers.common['X-VERSION'] = store.state.config.appVersion;

      //Also pre-load upcomming events.
      store.dispatch('getUpcommingEvents');
    } else {
      axios.defaults.headers.common['Authorization'] = null;
    }
  } else if (mutation.type === 'SET_HIDE_PASS_PERSONAL_INFO') {
    //Store the hide personal info to local storage for state restore.
    saveHidePassPersonalInfoToStorage(mutation.payload);
  } else if (mutation.type === 'SET_VERIFIER_SCAN_TYPE') {
    //Store the scan type to local storage for state restore.
    saveVerifierScanTypeToStorage(mutation.payload);
  } else if (mutation.type === 'SET_VERIFIER_INPUT_TYPE') {
    saveVerifierInputTypeToStorage(mutation.payload);
  } else if (mutation.type === 'SET_SELECTED_TAG_EVENT') {
    saveSelectedTagEventToStorage(mutation.payload);
  } else if (mutation.type === 'SET_SELECTED_TAG_EVENT') {
    saveSelectedTagEventToStorage(mutation.payload);
  } else if (mutation.type === 'SET_SELECTED_TAG_EVENT_LOCATION_ID') {
    saveSelectedTagEventLocationIDToStorage(mutation.payload);
  } else if (mutation.type === 'SET_VERIFIER_TAG') {
    saveVerifierTagToStorage(mutation.payload);
  } else if (mutation.type === 'SET_VERIFIER_TAG_TYPE') {
    saveVerifierTagTypeToStorage(mutation.payload);
  } else if (mutation.type === 'SET_VERIFIER_TAG_DESCRIPTION') {
    saveVerifierTagDescriptionToStorage(mutation.payload);
  }
});
