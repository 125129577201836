<template>
  <v-snackbar :value="show" @input="onDismiss" absolute centered top>
    {{ errorMessage }}
    <template v-if="errorActionMessage" v-slot:action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="onDismiss">
        {{ errorActionMessage }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'ScanError',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: 'Error'
    },
    errorActionMessage: {
      type: String,
      default: 'Close'
    }
  },

  methods: {
    onDismiss(input) {
      this.$emit('dismiss', input);
    }
  }
};
</script>

<style></style>
