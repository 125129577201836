<template>
  <v-container class="scanner-wrapper-container">
    <slot name="camera" v-if="inputType === 'camera'">No Scanner</slot>
    <slot name="manual" v-if="inputType === 'manual'">No Manual Entry</slot>
  </v-container>
</template>

<script>
export default {
  name: 'ScannerContainer',
  props: {
    inputType: String
  }
};
</script>

<style lang="scss">
.scanner-wrapper-container {
  max-width: 500px;
  padding: 0px;
  margin-bottom: 10px;

  @media (min-width: 600px) {
    padding-top: 20px;
  }

  .switch-message {
    margin-top: 3px;
    width: 100%;
    text-align: center;
    text-decoration: underline;
    color: var(--v-primary-base);
    cursor: pointer;
    font-size: 12px;

    &.manual {
      margin-top: -0px;
    }
  }
}
</style>
