var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scanner-container" },
    [
      !(_vm.scanning || _vm.restartScanningAfterResize)
        ? _c(
            "v-btn",
            {
              staticClass: "start-btn",
              attrs: {
                fab: "",
                large: "",
                color: "primary",
                "data-testid": "start-button"
              },
              on: { click: _vm.startScanning }
            },
            [_c("v-icon", [_vm._v("mdi-qrcode-scan")])],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            eager: "",
            fullscreen: "",
            dark: "",
            transition: "dialog-bottom-transition",
            "data-testid": "dialog"
          },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c(
            "div",
            { staticClass: "scanner-dialog-contents" },
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "toolbar",
                  attrs: { dense: "", flat: "", color: "rgba(0, 0, 0, 0.8)" }
                },
                [
                  _c("v-toolbar-title", [_vm._v(" Scanning... ")]),
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", "data-testid": "cancel-button" },
                          on: { click: _vm.stopScanning }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", {
                staticClass: "scanner",
                attrs: { id: "qr-code-scanner" }
              }),
              _vm.notAllowed
                ? _c(
                    "div",
                    { staticClass: "not-allowed" },
                    [
                      _c("v-icon", [_vm._v("mdi-video-off")]),
                      _c("span", [_vm._v("No Permission to use Camera")])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }