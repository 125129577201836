var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.inputType,
      attrs: { id: "verifier", "data-testid": "verifier" }
    },
    [
      _c("TopBar"),
      _c(
        "v-main",
        [
          _c("scanner-container", {
            attrs: { inputType: _vm.inputType },
            scopedSlots: _vm._u([
              {
                key: "camera",
                fn: function() {
                  return [
                    _c("PassCodeScanner", {
                      on: {
                        scanning: _vm.onScanning,
                        "not-allowed": _vm.onNotAllowed,
                        "successful-scan": _vm.onProcessPass
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "manual",
                fn: function() {
                  return [
                    _c("PassCodeManualEntry", {
                      ref: "manualEntry",
                      on: { "entry-confirmed": _vm.onProcessPass }
                    })
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("LastScanned", {
            attrs: {
              scanType: _vm.scanType,
              inputType: _vm.inputType,
              scanning: _vm.scanning,
              notAllowed: _vm.notAllowed,
              loadingPass: _vm.loadingPass,
              pass: _vm.scannedPass,
              hideTag: _vm.hideTag,
              tagName: _vm.tagName,
              tagType: _vm.tagType,
              showApprovingVisitor: _vm.approvingVisitor,
              approveVisitorError: _vm.approveVisitorError,
              isAllowedToApprove: _vm.userAllowedToApprove,
              hidePassPersonalInfo: _vm.hidePassPersonalInfo,
              hideBasicDetails: !_vm.userIsAllowedToViewBasicDetails
            },
            on: {
              "clear-pass": _vm.onClearPass,
              "approve-visitor": _vm.onApproveVisitor,
              "remove-tag": _vm.onRemoveTag
            }
          }),
          _c("ScanError", {
            attrs: { show: _vm.showError, errorMessage: _vm.errorMessage },
            on: { dismiss: _vm.onDismissError }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }