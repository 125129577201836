<template>
  <v-container class="last-scanned-container" max-width="500px">
    <template v-if="pass">
      <v-row>
        <v-col sm="12" class="header">
          <span class="text-h6">
            Scanned Pass
            <v-icon data-testid="privacy-btn" v-if="hidePassPersonalInfo" @click="ignoreHidePassPersonalInfo = !ignoreHidePassPersonalInfo">
              {{ ignoreHidePassPersonalInfo ? 'mdi-eye' : 'mdi-eye-off' }}
            </v-icon>
          </span>
          <v-btn text v-on:click="onClear">
            Clear
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
    </template>
    <v-row v-if="loadingPass">
      <v-col sm="12" class="pass-progress">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
    <PassCodeInfo
      v-else-if="pass"
      :pass="pass"
      @approve-visitor="onApproveVisitor"
      :showApprovingVisitor="showApprovingVisitor"
      :approveVisitorError="approveVisitorError"
      :isAllowedToApprove="isAllowedToApprove"
      :hidePassPersonalInfo="ignoreHidePassPersonalInfo ? false : hidePassPersonalInfo"
      :hideBasicDetails="hideBasicDetails"
    />
    <v-row v-else>
      <v-col sm="12" :class="`pass-info ${inputType}`">
        <v-icon large>{{ scanTypeIcon }}</v-icon>
        <span class="text-caption">
          {{ scanTypeDescription }}
        </span>
        <span class="text-subtitle-2">
          No Passes Scanned
        </span>
        <span class="text-caption">
          {{ scannerStatus }}
        </span>
        <Tag v-if="!hideTag && tagName" :name="tagName" :type="tagType" @remove="onRemoveTag" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PassCodeInfo from '@/components/pass-code-info.vue';
import Tag from './tag.vue';
import { scanTypeToDescription } from '@/utils/type-formats';

export const SCANNER_STATUS_START_SCANNING_MSG = 'Start scanning by pressing the scan button (bottom right)';
export const SCANNER_STATUS_NOT_ALLOWED_MSG = 'Allow permission to use Camera to verify passes';
export const SCANNER_STATUS_SCANNING_MSG = 'Scanning...';

export const MANUAL_ENTRY_MSG = 'Scan or enter pass ID';
export const SCANN_TYPE_ICON = {
  'incomming-visitors': 'mdi-airplane-landing',
  'locals-vaccinated': 'mdi-island'
};

export default {
  name: 'LastScanned',

  components: {
    PassCodeInfo,
    Tag
  },
  data() {
    return {
      ignoreHidePassPersonalInfo: false
    };
  },

  props: {
    scanType: String,
    inputType: String,
    scanning: Boolean,
    notAllowed: Boolean,
    loadingPass: Boolean,
    pass: Object,
    hideTag: {
      type: Boolean,
      default: false
    },
    tagName: String,
    tagType: String,
    showApprovingVisitor: Boolean,
    approveVisitorError: String,
    isAllowedToApprove: Boolean,
    hidePassPersonalInfo: Boolean,
    hideBasicDetails: Boolean
  },

  computed: {
    scannerStatus() {
      if (this.inputType === 'manual') {
        return MANUAL_ENTRY_MSG;
      }

      if (this.notAllowed) {
        return SCANNER_STATUS_NOT_ALLOWED_MSG;
      } else if (this.scanning) {
        return SCANNER_STATUS_SCANNING_MSG;
      }
      return SCANNER_STATUS_START_SCANNING_MSG;
    },

    scanTypeIcon() {
      return SCANN_TYPE_ICON[this.scanType];
    },

    scanTypeDescription() {
      return scanTypeToDescription[this.scanType];
    }
  },

  methods: {
    onClear() {
      this.$emit('clear-pass');
    },
    onApproveVisitor(id) {
      this.$emit('approve-visitor', id);
    },
    onRemoveTag() {
      this.$emit('remove-tag');
    }
  }
};
</script>

<style lang="scss">
.last-scanned-container {
  max-width: 500px;

  .header {
    display: flex;

    span {
      flex: 1;
    }

    button {
      flex: 0;
    }

    .v-icon {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .pass-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50%;
  }

  .pass-info {
    color: var(--v-secondary-lighten1);
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    .text-caption {
      max-width: 280px;
    }
    .v-icon {
      color: inherit;
    }
  }
  .pass-info.camera {
    margin-top: calc(50vh - 110px);
  }
  .pass-info.manual {
    margin-top: calc(50vh - 168px);
  }

  .pass-code-info-container {
    margin-top: 20px;
    min-height: 270px;
  }
}
</style>
