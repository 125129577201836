import Vue from 'vue';
import VueRouter from 'vue-router';
import Verifier from './components/VerifierWithRouter';

Vue.use(VueRouter);

export const routes = [
  {
    path: '/',
    name: 'Verifier',
    component: Verifier,
    children: [
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('@/views/Settings/Settings.vue')
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
export default router;
