<template>
  <div>
    <Verifier ref="verifier" />
    <router-view @dimissed="onDismissed"></router-view>
  </div>
</template>

<script>
import Verifier from '@/views/Verifier/Verifier.vue';

export default {
  name: 'VerifierWithRouter',
  components: { Verifier },
  mounted() {
    if (!this.$router?.currentRoute?.path?.endsWith('settings')) {
      this.$refs.verifier?.focusManualInput();
    }
  },
  methods: {
    onDismissed() {
      //Add focus to input on subviews call dismiss.
      this.$refs.verifier?.focusManualInput();
    }
  }
};
</script>
