<template>
  <div class="manual-entry-container">
    <v-text-field
      data-testid="pass-code-manual-entry-input"
      v-model="passInfo"
      append-icon="mdi-qrcode-scan"
      outlined
      placeholder="Use scanner or enter pass ID"
      clearable
      @keyup.enter.prevent="onEnterPressed"
      @keydown="onKeyDownAny"
      @paste.prevent="onPaste"
      :hint="hintMsg()"
      ref="inputField"
      persistent-hint
    >
    </v-text-field>
    <v-progress-linear data-testid="progress" class="progress" v-if="isScannerEntryInProgress" indeterminate height="5" />
    <v-divider />
  </div>
</template>

<script>
import { parseEdCardInfoFromData } from '@/utils/edcard';
import { parseAHAFlightInfoFromData } from '@/utils/aha-flight';
import { parseAHACaseInfoFromData } from '@/utils/aha-case';

const ignoredKeys = ['ArrowDown', 'Shift', 'Meta'];
export const PRESS_ENTER_HINT_MSG = 'Press enter to verify pass ID';
export const SCANNER_TIP_MSG = 'ⓘ Place cursor inside text field before scanning';

export default {
  name: 'PassCodeManualEntry',

  data() {
    return {
      pastedInfo: null,
      passInfo: '',
      canPressEnter: false,
      fastInputTimeout: null,
      fastInputText: '',
      lastKeyEntryTime: null,
      isScannerEntryInProgress: false
    };
  },

  watch: {
    passInfo(newVal) {
      this.canPressEnter = newVal?.length > 2;
    },
    isScannerEntryInProgress(newVal) {
      let input = this.$refs.inputField.$el.querySelector('input');
      input.style.opacity = newVal === true ? 0 : 1;
    }
  },
  methods: {
    hintMsg() {
      if (this.canPressEnter) {
        return PRESS_ENTER_HINT_MSG;
      }
      return SCANNER_TIP_MSG;
    },

    onKeyDownAny({ key }) {
      //Use the keydown any to check fast input text (for scanners).
      if (this.fastInputTimeout) {
        clearTimeout(this.fastInputTimeout);
      }
      if (!this.lastKeyEntryTime) {
        this.lastKeyEntryTime = Date.now();
      } else {
        const now = Date.now();
        const diff = now - this.lastKeyEntryTime;
        this.isScannerEntryInProgress = diff < 25; //Probably a scanner doing entries.
        this.lastKeyEntryTime = now;
      }
      this.fastInputTimeout = setTimeout(this.checkFastInputText, 100);

      if (ignoredKeys.includes(key)) {
        return; //ignored key.
      }

      if (key === 'Enter') {
        this.fastInputText += '\r\n';
      } else {
        this.fastInputText += key || '';
      }
    },

    checkFastInputText() {
      this.fastInputTimeout = null;
      this.isScannerEntryInProgress = false;
      this.lastKeyEntryTime = null;
      const edcard = parseEdCardInfoFromData(this.fastInputText);
      const ahaFlight = parseAHAFlightInfoFromData(this.fastInputText);
      const ahaCase = parseAHACaseInfoFromData(this.fastInputText);
      if (edcard && edcard.name) {
        //We will fake pasted info (see onPaste) and replace the text with only the ednumber.
        this.pastedInfo = this.fastInputText;
        this.passInfo = edcard.edNumber;
        this.onEnterPressed();
      } else if (ahaFlight && ahaFlight.edCardId) {
        //We will fake pasted info (see onPaste) and replace the text with only the ednumber.
        this.pastedInfo = this.fastInputText;
        this.passInfo = ahaFlight.edCardId;
        this.onEnterPressed();
      } else if (ahaCase && ahaCase.udid) {
        //We will fake pasted info (see onPaste) and replace the text with only the case udid.
        this.pastedInfo = this.fastInputText;
        this.passInfo = ahaCase.udid;
        this.onEnterPressed();
      } else if (this.fastInputText === '\r\n') {
        this.onEnterPressed();
      }
      this.fastInputText = '';
    },

    onEnterPressed() {
      if (this.fastInputTimeout) {
        return; //We are still parsing fast input.
      }
      const data = this.pastedInfo || this.passInfo;
      this.$emit('entry-confirmed', data);
      this.pastedInfo = null;
      this.canPressEnter = false;
    },

    onPaste(evt) {
      const pastedText = evt.clipboardData.getData('text');

      const edcard = parseEdCardInfoFromData(pastedText);
      const ahaFlight = parseAHAFlightInfoFromData(pastedText);
      const ahaCase = parseAHACaseInfoFromData(pastedText);
      if (edcard) {
        //Use the pasted data to call the next emit on enter.
        //This is a scanner specific feature.
        this.pastedInfo = pastedText;
        //If this is an ed card then we only leave the number in the input.
        this.passInfo = edcard.edNumber;
      } else if (ahaFlight) {
        //Use the pasted data to call the next emit on enter.
        //This is a scanner specific feature.
        this.pastedInfo = pastedText;
        //If this is an ed card then we only leave the number in the input.
        this.passInfo = ahaFlight.edCardId;
      } else if (ahaCase) {
        //Use the pasted data to call the next emit on enter.
        //This is a scanner specific feature.
        this.pastedInfo = pastedText;
        //If this is an ed card then we only leave the number in the input.
        this.passInfo = ahaCase.udid;
      }
    },

    focus() {
      this.$refs.inputField?.focus();
    }
  }
};
</script>

<style lang="scss">
.manual-entry-container {
  position: relative;
  @media (max-width: 600px) {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .info-message {
    font-size: 12px;
    .v-icon {
      font-size: 16px;
      margin-right: 5px;
      margin-top: -2px;
    }
    margin-bottom: 15px;
  }
  .progress {
    position: absolute;
    top: 50px;
    left: 0px;
    right: 0px;

    @media (max-width: 600px) {
      top: 70px;
      left: 10px;
      right: 10px;
      width: unset !important;
    }
  }
}
</style>
